//---------------------------------------------
//
//  Nav Component
//
//    1. Imports
//    2. Component
//
//---------------------------------------------

//---------------------------------------------
// 1. Imports
//---------------------------------------------

// @import "../variables";

//---------------------------------------------
// 2. Component
//---------------------------------------------

// Nothing here yet
@media screen and (max-width: 766px){
    .navbar {
        margin-top:3px;
    }
    .tab-panel {
        position: relative;
    }
    .navbar-toggler {
        position: absolute;
        right: 0px;
        top: -31px;
        z-index: 100;
        background: $white;
    }
    .nav-tabs {
        border: 1px solid #CCC;

        > li {
            display:block;
            width:100%;

            .nav-link {
                text-align: left;
                border: none;
                padding-top: 1em;
                padding-bottom: 1em;
                &.active {
                    background: #EEE;
                }
            }
        }
    }
}

.nav-fill {
    width: 100%;
}