//---------------------------------------------
//
//  Table of Contents
//
//    1. Basics
//    2. Page Layout
//    3. Components
//
//---------------------------------------------

//---------------------------------------------
// 1. Basics
//---------------------------------------------

@import "variables";
@import "bootstrap";
@import "fonts";

//---------------------------------------------
// 2. Page Layout
//---------------------------------------------

body {

    #home {
        p {
            margin: 0;
            letter-spacing: .1em;
            padding: 0 0 calc($box-padding * 2) 0;
        }
    }

    .heading-group {
        h1 {
            margin: 0;
            padding: 0;
            padding-bottom: $box-padding;
            font-family: $font-family-serif;
        }

        p {
            font-weight: 500;
        }

        sup {
            font-size: $font-size-sm;
        }
    }

    .time-location {
        border-bottom: $border-definition;
        border-top: $border-definition;
    }

    .date-time {
        padding: 1em 0 1em 0;
        span {
            display: block;
        }
        .date {
            font-family: $font-family-serif;
            font-size: calc($font-size-lg * 1.5);
            padding: .1em 0;
        }
        @include media-breakpoint-up(sm) {
            border-right: $border-definition;
        }
    }
    .location {
        padding: 1em 0 1em 2em;
    }
    .location,
    .date-time {
        @include media-breakpoint-up(sm) {
            padding: 2em;
        }
    }

    .thumbnail {
        height: 175px;
        object-fit: cover;
    }

    .card {
        @include media-breakpoint-up(sm) {
            flex: 1 0 45%; /* explanation below */
            margin: 10px;
        }
        @include media-breakpoint-up(lg) {
            flex: 1 0 21%; /* explanation below */
        }
        margin: 0 10px 30px;
        &-text {
            padding: .5em 0 1em;
            p {
                margin: 0;
                padding: 0 0 $box-padding;
            }
        }
    }
}

//---------------------------------------------
// 3.Components
//---------------------------------------------

@import "components/nav";